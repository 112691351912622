$search-filters-bg : #F7F7F7;

.filters {
  background: $search-filters-bg;
  padding: 16px 29px 17px;
}

.search_filters {

  &--title {
    font-size: 14px;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 0;
    font-family: $barlow-font;
    font-weight: 500;
    min-width: 235px;
  }

  .facet__block {

    .custom-control-input:checked~.custom-control-label::before {
      border-color: $text-color;
      background-color: $white;
    }

    .custom-control-label {
      padding-left: 5px;
      color: $main-color;

      &:before {
        border-color: $text-color;
      }

      &:before,
      &:after {
        height: 14px;
        width: 14px;
        transform: translateY(-50%);
        top: 49%;
      }
    }
  }
}

.facet__block:not(:last-child) {
  margin-bottom: 0;
  border-bottom: none;

}

.facet__header {
  position: relative;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: $spacer/1.5;

  .icon-collapse {
    font-size: 13px;
    margin-left: 10px;

    svg {
      fill: $text-color;
    }
  }
}

.facet__title {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $text-color;
  display: flex;
  align-items: center;
  cursor: pointer;
}


.left-column .search_filters {
  @extend .card-block;
}

.custom-color {
  &.custom-control {
    padding-left: 0;
    margin-bottom: .25rem;
  }

  .custom-control-label:before,
  .custom-control-label:after {
    display: none
  }

  .color {
    margin-right: .25rem;
    border: none;
  }

  .color__label {
    //dis
    line-height: 1.25;
    display: inline-block;
  }

  &.custom-control--active {
    .color {
      border: 2px solid black;
    }
  }
}

.btn--clearfilter {
  @extend .btn;
  @extend .btn-danger;
  @extend .btn-block;

  @include media-breakpoint-desktop {
    margin-bottom: $spacer;
    background: none !important;
    padding: 0;
    color: #E60F0F !important;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    display: inline-block;
    width: auto;
    box-shadow: none;
  }
}

#search_filter_toggler {
  padding: 0;
  border: none;
  font-size: 14px;

  &:before {
    @extend %material-icons;
  }
}

//
// .active-filter__title{
//   opacity: 0
// }
.active_filters__list {

  .clear-all-wrapper {
    opacity: 0;

    .btn--clearfilter {
      pointer-events: none;
    }
  }

  .facet__title {
    display: none;
  }
}

// .active_filters{
//   padding: 0 20px 15px;
//   background-color: $grey;
//   margin-bottom: $spacer*1.25;
// }
.active_filters__list-item {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: $spacer/4;

  &:hover,
  & {
    color: $main-color;
  }

  i {
    color: $black;
    font-style: normal;
    margin-right: 10px;
  }

}

#offcanvas_search_filter {

  .modal-header .close {
    font-size: 36px;
    color: $main-color;
  }

  .modal-body {
    background: $grey;
  }

  .facet__block {
    padding: 15px 0;
    width: 100%;
  }
}


.faceted-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(25, 25, 25, 0.5);
  z-index: $zindex-modal+1;
}

.faceted-overlay .overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.faceted-overlay .overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.faceted-overlay .spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

#search_filters .ui-slider-horizontal .ui-slider-handle {
  margin-left: -1px;
  cursor: pointer;
}

#search_filters .ui-widget-header {
  background: #555;
}

#search_filters .ui-slider .ui-slider-handle {
  top: -.45em;
  width: 0.4em;
  background: #fff;
  border: 1px solid #555;
}

#search_filters .ui-slider-horizontal {
  height: .4em;
}

@include media-breakpoint-desktop {
  .filters {
    min-height: 73px;
  }

  .active-filter__title,
  .search_filters--title {
    min-width: 235px;
  }

  .facet__block:not(:last-child) {
    padding-bottom: 0;
  }
}

@include media-breakpoint-mobile {
  .facet__block:not(.show) {
    padding-bottom: 0;
  }

  .filters {
    background-color: #F7F7F7;
    padding: 12px 26px 13px;

  }

  #search_filter_toggler {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;

    &:before {
      content: "\e313";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  #offcanvas_search_filter .facet__block {
    padding-left: 14px;
    padding-right: 14px;
  }

  .search_filters .facet__block .custom-control-label,
  #search_filter_toggler,
  .facet__title {
    color: $text-color;
  }
}