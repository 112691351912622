#checkout {
    @include media-breakpoint-mobile {
        .order-options {
            width: 100% !important;
        }
        .delivery-options {
            width: 100% !important;
            .delivery-option {
                > div {
                    margin-left: -15px;
                    margin-right: -15px;
                }
            }
        }

        #content-checkout-addresses-step {
            .continue {
                width: 100%;
            }
        }
    }
    
}