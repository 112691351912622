
// @include media-breakpoint-desktop {
// 	#product {
// 		.product-img {
// 			height: 634px;
// 			overflow: hidden;
// 		}
// 	}
// }

.product-reference,
.product-reference label {
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	color: $main-color;
}

.product-brand {
	img {
		@extend .img-fluid;
		max-height: 44px;
		@include media-breakpoint-mobile {
			width: 95px;
		}
	}

	@include media-breakpoint-mobile {
		#product & {
			position: absolute;
			top: 40px;
			left: 15px;
			z-index: 3;
		}
	}
}

.infos__brand-name {
	font-size: 24px;
	display: inline-block;
	line-height: 29px;
	font-weight: 800;
	text-transform: uppercase;
}

.current-price {
	font-size: 36px;
	font-weight: 900;
	line-height: 1.2;
}

.has-discount {
	.regular-price {
		font-size: 28px;
		font-weight: 800;
		color: $dark-grey;
	}

	.current-price {
		color: #ff0000;
	}
}

.product--title {
	font-size: 24px;
	line-height: 29px;
	font-weight: 500;
	text-transform: uppercase;
}

.infos__advantages {
	span {
		display: inline-block;
		background: $grey;
		padding: 7px 14px;
		font-size: 9px;
		font-weight: 800;
		text-transform: uppercase;
		margin-right: 10px;
	}
}

.short-description {
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 20px 0;
	padding-bottom: 20px;
	border-bottom: 1px solid $main-color;

	@include media-breakpoint-mobile {
		font-size: 16px;
	}
}

.product-variants {

	/*** Custom Radio ***/
	.custom-radio {
	    position: relative;
	    padding: 0;
	    display: inline-block;
	    margin-right: 5px;

	    input {
	        position: absolute;
	        opacity: 0;
	        cursor: pointer;
	        height: 0;
	        width: 0;
	        margin: 0;

	        &:checked + .custom-control-label {
	            background: $main-color;
	            color: $white;
	        }
	    }

	    label {
	        position: relative;
	        height: 33px;
	        min-width: 33px;
	        cursor: pointer;
	        text-align: center;
	        display: inline-block;
	        border: 1px solid $main-color;
	        font-size: 12px;
	        font-weight: 800;
	        line-height: 33px;
	        color: $main-color;

	        &:before,
	        &:after {
	            display: none;
	        }
	    }
	}

	.product-variants-item {
		margin-bottom: 0;

		@include media-breakpoint-mobile {
	    	margin-bottom: 15px;

	    	.custom-radio label {
	    		height: 58px;
	    		width: 58px;
	    		line-height: 58px;
	    		font-size: 20px;
	    	}
	    }
	}
}

.variants-label,
.quantity__label {
	display: block;
	width: 100%;
	font-size: 14px;
	font-weight: 800;
	text-transform: uppercase;
	margin-bottom: 12px;
	color: $main-color;
}

.qty {

	input {
		background: $main-color;
		color: $white;
		font-size: 18px;
		font-weight: 500;
		max-width: 80px;
		border: none;
		padding: 4px;
	}

	@include media-breakpoint-mobile {

		.bootstrap-touchspin .btn {
			padding: 5px 10px;
		}
	}
}

.product-manufacturer {
	margin-bottom: 20px;

	.label {
		font-weight: 900;
		display: block;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	.value a {
		font-weight: 500;
		text-transform: uppercase;
		color: $text-color;
	}
}

.data-sheet {

	.name {
		font-weight: 900;
		display: block;
		text-transform: uppercase;
	}

	.value {
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 20px;
	}
}

@include media-breakpoint-mobile {
	.product-thumbs {
		height: 18px !important;
		margin-top: 30px !important;

		.product-thumb {
			height: 9px;
			width: 9px;
			background: $grey;
			border: none;

			&.slick-current {
				background: $main-color;
				border: none;
			}

			.js-thumb {
				display: none;
			}
		}

		.slick-track {
			width: auto !important;
			transform: none !important;
			display: flex;
			justify-content: center;
		}
	}
}

.product-accessories {
	margin-top: 70px;
	margin-bottom: 84px;

	.products .product-miniature .card.card-product {
		border-left: none;
	}

	.products {
		.product-miniature {
			padding: 0;

			&:not(:first-child) .card-product {
				border-left: 1px solid $main-color;
			}
		}
	}
}

@include media-breakpoint-desktop {
	#product {
		.product-prices>div:not(.has-discount) {
			margin-top: -10px;
		}
		.product-brand {
			margin-top: -5px;
		}
	}
}