.l-header {
  background-color: $header-bg;
  transition: all .3s ease;
   @include media-breakpoint-desktop {
    min-height: 211px;
    position: relative;
    z-index: 2;
   }

  .is-sticky {
    .header-top {
      border-bottom: 1px solid $main-color;
    }

    @include media-breakpoint-desktop {
      .header-top {
        padding: 0;
      }

      .header__languages {
        display: none !important;
      }
      .header__nav {
        order: 2;
        width: auto;
        flex: auto;
        position: static;
      }

      .header__actions {
        order: 4;
        max-width: 56px;
      }

      .header__search {
        order: 3;
        flex: none;

        form.search-widget {
          max-width: 58px;
          margin: 0;
          transition: all ease-in-out .3s;

          button.search-widget__btn {
            padding: 8px 20.5px;
            img {
              max-width: 17px;
            }
          }
        }
      }

      .header__nav {
        span.mm_menu_content_title {
          padding: 38px 10px 37px;
          font-size: 14px;
        }

        .ets_mm_megamenu_content {
          margin: 0;
        }
        .mm_menus_ul {
          display: flex;
          justify-content: center;
        }
      }

      .header__logo {
        max-width: 88px;
        order: 1
      }

      .ets_mm_megamenu_content .ets_mm_megamenu_content_content,
      .ets_mm_megamenu {
        position: static;
      }

    }

  }
}
@include media-breakpoint-desktop {
  .header__languages {
    margin-bottom: 15px;
  }
}

#checkout .l-header {
  position: relative;
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  padding-top: $spacer*1.25;
  transition: all .3s ease-in;
  background-color: $header-bg;

  @include media-breakpoint-mobile {
    padding-bottom: $spacer*1.25;
  }
}

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-mobile{
  .header__logo > a,
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    flex: auto;
    order: 1;
    background: $white;
    z-index: 99;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
  .toggle-search {
    display: inline-block;
    margin-right: 1.35rem;
  }
}
// @include media-breakpoint-desktop(){
//   .header-top__col{
//     width: 30%;
//   }
// }
// @include media-breakpoint-up(xl) {
//   .header-top__col{
//     width: 25%;
//   }
// }


//
//.header-nav{
//  border-bottom: 1px solid $header-nav-border-color;
//  background-color: $header-nav-bg-color;
//  color:$header-nav-color;
//  padding-top: $header-nav-padding-y;
//  padding-bottom: $header-nav-padding-y;
//  & a:not(.dropdown-item), .btn{
//    color: $header-nav-color;
//  }
//}
////.header-top{
////  border-bottom: 1px solid $header-top-border-color;
////}
//@include media-breakpoint-mobile {
//  .header__container{
//    padding-left: 0;
//    padding-right: 0;
//  }
//  .logo{
//    width:100%;
//    max-width: 115px;
//  }
//}
