.search-widget{
  max-width: 450px;
  margin: 0 auto;

  .search-widget__group {
    position: relative;

    .search-widget__input-right {
        height: 42px;
        background: $grey;
        border-radius: 21px;
        padding: 15px 30px;
        border: none;
        box-shadow: none;
        color: $text-color;
        text-transform: uppercase;

        ::-webkit-input-placeholder { /* Edge */
          color: $text-color;
          font-weight: 500;
          text-transform: uppercase;
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $text-color;
          font-weight: 500;
          text-transform: uppercase;
        }

        ::placeholder {
          color: $text-color;
          font-weight: 500;
          text-transform: uppercase;
        }
    }

    .search-widget__btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        padding: 10px 23px;
        border-radius: 21px;
        background: $main-color;
        color: $white;
        font-size: 14px;
    }
  }
}

@include media-breakpoint-mobile{
  .header__search {
    border-top: 1px solid $dark-grey;
    margin-top: 10px;

    &--mobile {
      padding: 10px 0;
    }

    .search-widget{
      max-width: unset;

      .search-widget__input-right,
      .search-widget__btn {
        border-radius: 0px;
      }

      .search-widget__btn {
        padding: 10px 15px;
      }
    }
  }
}
