.l-footer {
  color: $text-muted;


  .cms-page-link,
  .account-list a{
    color: $text-muted;
    &:hover{
      color: theme-color('primary');

    }
  }
}
.footer-container{
  margin-top: 42px;
}

.footer__title{
  color: $text-color;
  &:visited,&:hover{
    color: $text-color;
  }
  text-transform: uppercase;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 24px;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;
    

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:  rotate(180deg);
    }
  }

  @include media-breakpoint-mobile {
    margin-bottom: 16px;

    &[data-toggle="collapse"] {
      margin-bottom: 14px;
      padding-bottom: 20px;
      border-bottom: 1px solid $main-color;
      position: relative;

      svg {
        position: absolute;
        right: 0;
        top: 3px;
        transition: all ease-in-out .3s;
      }

      &[aria-expanded="true"] {
        svg {
          transform: rotate(-90deg);
          transition: all ease-in-out .3s;
        }
      }
    }

  }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}
