/* PRODUCT MINIATURE */
$pm-bg-color: null;
$pm-border-width: 0.5px;
$pm-border-color:  #06adf2;
/* product title */
$pm-name-color: #042761;
$pm-name-hover-color: darken($pm-name-color, 5%);
$pm-name-font-weight: 500;
$pm-name-font-family: 'Barlow';
$pm-name-font-size: 18px;
$pm-name-line-height: 22px;
$pm-brand-name-font-size: 18px;
$pm-brand-name-font-weight: 800;
$pm-name-margin-bottom: 0;
$pm-name-hover-border-width: null;
$pm-name-hover-border-color: transparent;

$pm-highlight-bg-color: null;
$pm-highlight-translateY: 0;