.sticky-wrapper {
    &:not(.is-sticky) {
        @include media-breakpoint-desktop {
            min-height: 210px
        }
    }
}
.is-sticky {
    z-index: 90;
}

