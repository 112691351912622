#manufacturer {
	h1 {
		width: auto;
		display: table;
		margin: 30px auto 50px;

		@include media-breakpoint-mobile {
			margin-top: 14px;
			margin-bottom: 20px;
			font-size: 19px;
			letter-spacing: 0.95px;
		}
	}
}