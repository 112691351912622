.breadcrumb {
	background: none;
	padding: 30px 0;
	margin-bottom: 0;

	&-item,
	&-item.active span,
	&-item a {
		font-size: 12px;
		color: $text-color;
		font-weight: 500;
	}

	&-item + &-item::before {
		content: ">";
	}
}