#authentication,
#password {
	.page--title {
		background: $main-color;
		padding: 14px;
		margin-bottom: 80px;

		@include media-breakpoint-mobile {
			margin-bottom: 44px;
		}

		h1 {
			margin-bottom: 0;
			font-size: 30px;
			font-weight: 900;
			color: $white;
		}
	}

	#wrapper {
		padding-bottom: 80px;
		position: relative;
		&:before {
			background: #F7F7F7;
			padding-bottom: 80px;
			content: "";
			position: absolute;
			left: 15px;
			height: 100%;
			top: 0;
			right: 15px;
			z-index: -1;

			@include media-breakpoint-down(md) {
				left: 0;
				right: 0;
			}
		}
	}

	.no-account {
		@extend .row;
		background: $dark-grey;
		border-radius: 21px;
		margin-bottom: 45px;

		a {
			@extend .col;
			padding: 13px 0;
			font-size: 14px;
			font-weight: 500;
			text-align: center;
			color: $text-color;

			&.current-link {
				background: $main-color;
				border-radius: 21px;
				color: $white;
				font-weight: 800;
			}
		}

		@include media-breakpoint-down(md) {
			background: none;
			margin:  0;
			display: block;

			a {
				background: $dark-grey;
				display: block;
				width: 100%;
				border-radius: 21px;
				margin-bottom: 20px;
			}
		}
	}

	#customer-form{
		.form-fields {
			margin-bottom: 40px;
			@include media-breakpoint-desktop {
				margin-bottom: 60px;
			}
		}
	}

	#login-form,
	#customer-form,
	.forgotten-password,
	.new-password {
		@include media-breakpoint-desktop {
			.form-fields {
				margin-left: 60px;
				margin-right: 60px;
			}
		}

		label:not(.custom-control-label) {
			width: 100%;
			margin-bottom: 12px;
		}

		.form-group:first-child {
			padding-bottom: 25px;
			border-bottom: 1px solid $main-color;
		}

		.custom-radio {
		    min-height: 26px;

		    .custom-control-label {
		    	font-size: 14px;
		    	font-weight: 500;
		    	color: $text-color;
		    	text-transform: initial;
		    	padding-left: 26px;
		    	line-height: 26px;
		    }

		    .custom-control-input {
		      height: 26px;
		      width: 26px;
		      cursor: pointer;
		    }

		    .custom-control-label::before {
		      height: 26px;
		      width: 26px;
		      border: 1px solid $text-color;
		      box-shadow: none;
		      top: 0;
		      background-color: transparent;
		    }

		    .custom-control-input:checked ~ .custom-control-label::before {
		      background-color: $white;
		      border-color: $main-color;
		    }

		    .custom-control-label::after {
		      height: 14px;
		      width: 14px;
		      border-radius: 50%;
		      top: 6px;
		      left: -9px;
		    }

		    .custom-control-input:checked ~ .custom-control-label::after {
		      background-image: none;
		      background: $main-color;
		    }
		}
	}

	.login-form,
	.register-form,
	.forgotten-password,
	.new-password {
		::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		  color: $main-color;
		  font-weight: 500;
		  text-transform: uppercase;
		  opacity: 1; /* Firefox */
		}

		:-ms-input-placeholder { /* Internet Explorer 10-11 */
		  color: $main-color;
		  font-weight: 500;
		  text-transform: uppercase;
		}

		::-ms-input-placeholder { /* Microsoft Edge */
		  color: $main-color;
		  font-weight: 500;
		  text-transform: uppercase;
		}

		.form-control,
		.form-control:focus,
		.form-control:active {
			background: $white;
			padding: 20px 28px;
			border: none;
		  	box-shadow: none;
		  	color: $main-color;

			&:focus {
		    	border: none !important;
		    	box-shadow: none !important;
			}
		}

		label, label small {
		    font-weight: 800;
		    text-transform: uppercase;
		}

		.form-group,
		.form-row {
			margin-bottom: 28px;

			.custom-control label,
			.forgot-password a {
				font-size: 11px;
				font-weight: 500;
				color: $main-color;
				text-transform: uppercase;
				margin: 0;
			}

			.forgot-password {
				text-decoration: underline;
			}
		}
	}
}