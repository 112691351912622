//.blockcart{
//  color:$blockcart-color;
//  background-color: $blockcart-background-color;
//  padding:$blockcart-padding;
//}
//.blockcart.inactive{
//  color:$blockcart-inactive-color;
//  background-color: $blockcart-background-color;
//  .material-icons{
//    color:$blockcart-inactive-color;
//  }
//}

.cart-container {
  border-color: $main-color;
  margin-bottom: 20px;

  @include media-breakpoint-down(md) {
    border-left: none;
    border-right: none;
  }
}

.product-line-actions{
  display:flex;
  justify-content: space-between;
  align-items: center;
  .input-group.bootstrap-touchspin{
    max-width: 130px;
  }

}
.product-line-grid{
  .modal-title {
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: $main-color;
  }

  .product-line__title{
    margin-bottom: 0;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: $text-color;

    @include media-breakpoint-mobile {
      font-size: 18px
    }
  }
  .product-price{
    display: block;
    font-size: 24px;
    font-weight: 800;
    line-height: 1.1;

    .discount{
      font-size: $font-size-sm;
      line-height: 1;
    }

    &.has-discount {
      .product-price {
        color: red;
      }
    }
  }
  .product-discount{
    font-size: 18px;
    font-weight: 800;
    color: $dark-grey;
  }

}
.product-line__img{
  margin-right: $spacer;
}
@include media-breakpoint-mobile {
  .product-line__img{
    width: 100px;
    height: auto;
  }
  .product-line-actions {
    display: block;
    margin-top: 20px;
  }

  .cart-line-product-actions {
    position: absolute;
    top: -140px;
    right: 0;
  }
}
@include media-breakpoint-mobile() {
  // .product-line__img{
  //   width: 50px;
  // }
}

.cart__card-body{
  position: relative;
  
  @include media-breakpoint-up(lg){
    padding: 50px;
  }

  @include media-breakpoint-down(md) {
    padding: 15px 0;
  }

 .cart__card-loader{
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   align-items: center;
   justify-content: center;
   display: none;
   background-color: rgba($white, .85);
   opacity: 0;
   transition: $transition-fade;
   z-index: 3;
 }
  &.is--loading{
    .cart__card-loader {
      display: flex;
      opacity: 1;
    }

  }
}
.nav-tabs--center{
  justify-content: center;
}
// @include media-breakpoint-mobile{
//   .checkout {
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     z-index: 2;
//     padding: 1rem;
//     background-color: rgba(0,0,0,.4);
//     a{
//       width: 100%;
//     }
//   }
// }
.product-line__body{
  padding-right: $spacer/4;

  .infos__brand-name {
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;

    @include media-breakpoint-mobile {
      font-size: 18px
    }
  }

  .small {
    display: block;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;

    @include media-breakpoint-mobile {
      font-size: 18px
    }
  }
}

//order confirmation table
.total-value{
  background-color: $gray-300;
  font-weight: 700;
}
.table__title-head{
  margin-bottom: 0;
  font-size: $font-size-base;

}
/* CART */
.promo-code{
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}
.promo-code-alert {
  display: none;
}
.cancel-promo{
  text-align: center;
}
.card-body .separator{
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}

.cart-summary-line{
  display: flex;
  justify-content: space-between;

}

.card-body--summary{
  .cart-total{
    padding: 30px 0 !important;

    .label {
      font-size: 18px;
      font-weight: 800;
      color: $main-color;
      text-transform: uppercase;
    }

    .value {
      color: $text-color;
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase;
    }

    padding: $spacer/4;
    .label{
      margin-bottom: 0;
    }
  }
  &.card-body{
    padding-bottom: 70px;
  }
}
.media-list__item:not(:last-child){
  margin-bottom: $spacer/2;

}

//cart summary
.link__showsummary{
  .material-icons{
    @extend .icon-collapse;
  }
  &[aria-expanded=true] .material-icons{
    transform: rotateZ(180deg);

  }
}
@include media-breakpoint-down(lg) {
  .cart-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.cart-item {
  border-bottom: 1px solid $main-color;
  margin-bottom: 40px;
  padding-bottom: 40px;
  padding-top: 40px;

  @include media-breakpoint-mobile {
    margin-bottom: 20px;
  }

  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
   @include media-breakpoint-mobile {
      padding-bottom: 30px;
   }
  }
}
.product-line-info{
  margin-bottom: $spacer/2;
}
.cart-line-product-actions {
  i {
    color: $main-color;
    font-weight: 900;
  }
}
.promo-code-button{
  margin-bottom: 0;

  .collapse-button {
    display: block;
    text-align: left;
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 30px 0;
    border-bottom: 1px solid $main-color;
    color: $main-color;

    &:hover {
      color: $main-color;
    }
  }

  .collapse-button[aria-expanded=true] {
    display: none;
  }
}
.with-discounts{


}
.promo-highlighted{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
  margin-bottom: $spacer/2;
}
.promo-discounts{
  font-weight: $font-weight-bold;
  text-align: center;
  color: $gray-700;
  .code{
    color: $tertiary;
  }
}
.form__add-voucher{
  margin-bottom: $spacer;
}
.promo-code{
  &__content{
    padding: 22px 30px;
    background-color: $dark-grey;

    @include media-breakpoint-down(md) {
      padding: 15px 25px; 

      .input-group {
        display: block;

        .form-control {
          width: 100%;
          margin-bottom: 18px;
        }

        .input-group-append {
          position: relative !important;
          justify-content: center;
          transform: none !important;
        }
      }
    }

    .input-group {

      .form-control {
        height: 42px;
        background: $grey;
        border-radius: 21px;
        padding: 15px 30px;
        border: none;
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $text-color;
        }

        &:-ms-input-placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $text-color;
        }

        &::-ms-input-placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $text-color;
        }
      }

      .input-group-append {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;

        .btn-primary {
          color: $text-color;
          padding: 9.5px 20px;
          border-radius: 21px;
          font-size: 14px;
          min-width: 180px;

          &:hover,
          &:focus,
          &:active {
            background: $main-color;
            box-shadow: none;

            span {
              text-decoration: underline;
              color: $white;
            }
          }
        }
      }
    }
  }

  &__text {
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 10px;
  }

 .cancel-promo {
   text-align: center;
   display: block;
   color: $text-color;
 }
}

.cart-grid-right {
  .cart-summary {
    border: none;

    .card-body--summary {
      background: $grey;
      margin-bottom: 20px;
    }

    .cart-detailed-info {
      padding-top: 30px;

      .articles-title,
      #cart-subtotal-products .value {
        font-size: 18px;
        font-weight: 800;
        text-transform: uppercase;
        color: $main-color;
      }
    }

    #cart-subtotal-products {
      padding-bottom: 30px;
      border-bottom: 1px solid $main-color;

      .label {
        font-size: 14px;
        font-weight: 500;
        color: $main-color;
        text-transform: uppercase;
      }
    }

    
    #cart-subtotal-shipping {
      padding: 30px 0;
      border-bottom: 1px solid $main-color;

      .label {
        font-size: 18px;
        color: $main-color;
        font-weight: 800;
        text-transform: uppercase;
      }

      .value {
        color: $main-color;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 18px;
      }
    }

    .promo-code__content {
      background: $grey;

      .form-control {
        background: $white;
      }
    }

    .card-footer {
      background: none;
      border: none;
      padding: 0;
    }
  }
}
