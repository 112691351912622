.slick-arrow {
    position: absolute;
    top:0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 1;
    outline: none;
    box-shadow: none;

    & > i{
        font-size: 2*$font-size-base;
        line-height: 1;
        background: #fff;
        color: $dark-grey;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        border-radius: 50%;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &.slick-disabled {
        opacity: 0;
    }

 }
.slick-prev {
    left:0;
    transform: rotate(180deg);
}
.slick-next{
    right:0;
}
.slick-slide figure{
    margin: 0;
}
.slick__arrow-outside {
    .slick-prev {
        transform:translateX(-50px);
    }
    .slick-next{
        transform:translateX(50px);
    }
}
.slick__arrow-large{
    .slick-arrow i{
        font-size: 3*$font-size-base;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
        background: transparent;
        color: #fff;
    }
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 40px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;

    li {
        position: relative;

        display: inline-block;

        width: 20px;
        height: 20px;
        margin: 0 4px;
        padding: 0;

        cursor: pointer;

        button {
            font-size: 0;
            line-height: 0;

            display: block;

            width: 20px;
            height: 20px;
            padding: 5px 4px;

            cursor: pointer;

            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:focus,
            &:hover {
                outline: none;
                :before {
                    opacity: 1;
                }
            }

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;

                width: 9px;
                height: 9px;

                content: '';
                
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                background-color: $white;
                transform: translate(-50%, -50%);
                    
            }
        }

        &.slick-active button:before {
            background-color: $main-color;
        }
    }
}
