.account-links{
  background: $grey;
  padding: 80px 175px 0;

  @include media-breakpoint-mobile {
    padding: 30px 15px;
  }

  @extend .align-items-stretch;
  > a{
    margin-bottom: 30px;
  }
  .link-item{
    @extend .card;
    background: $text-color;
    color: $white;
    border: none;
    box-shadow: none;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
    height: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 264px;

    &:hover {
      color: $white;
    }

    img {
      margin-bottom: 30px;
      height: 72px;

    }
  }
}

.address{
  @extend .card;
  height: 100%;
  border: none;
  background-color: transparent;
}
.address-header{
  @extend .card-header;
  background-color: transparent;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
}
.address-body{
  @extend .card-body;
  background: $white;
  padding: 30px 15px;
  font-size: 18px;
  font-weight: 500;
  color: $text-color;
  text-transform: uppercase;
}
.address-footer{
  @extend .card-footer;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;

  a {
    color: $text-color;
    text-transform: uppercase;
  }
}

.form--100{
  min-width: 100%;
  width: 100%;
}

.account-page-subtitle {
  font-size: 18px;
  font-weight: 800;
  border-bottom: 1px solid $text-color;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.page-content--history,
.page-content--addresses,
.page-content--order-follow,
.page-content--order-detail,
.page-content--discount,
.page-content--identity,
.page-content--address,
.page-content--module-ps_emailalerts-account,
.page-content--order-confirmation {
  background: $grey;
  padding: 80px 100px;

  @include media-breakpoint-mobile {
    padding: 30px 15px;
  }
}

.page-header--my-account,
.page-header--history,
.page-header--addresses,
.page-header--order-follow,
.page-header--order-detail,
.page-header--discount,
.page-header--identity,
.page-header--address,
.page-header--module-ps_emailalerts-account,
.page-header--order_confirmation,
#cms .page-header--cms {
  @extend .row-full-mobile;
  position: relative;
  background: $main-color;
  border: none;
  padding: 12px 20px;
  text-align: center;

  h1 {
    font-size: 30px;
    font-weight: 800;
    color: $white;
    @include media-breakpoint-mobile {
      font-size: 18px;
    }
  }

  p {
    color: $white;
  }

  .account-link {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    color: $white;

    @include media-breakpoint-down(sm) {
      position: relative;
      display: block;
      left: 0;
      top: 0;
      margin-top: 12px;
    }
  }
}

.page-footer--history,
.page-footer--order-follow,
.page-footer--discount,
.page-footer--addresses,
.page-footer--address,
.page-footer--identity,
.page-footer--module-ps_emailalerts-account {
  display: none;
}

.account-footer-links,
.addresses-footer {
  background: $grey;
  padding: 80px 0;
  width: 100%;
  text-align: center;
}

.bdm-table {
  background-color: transparent;
  color: $text-color;

  tbody tr {
    background: $white;
    font-weight: 800;
    text-transform: uppercase;
  }

  th {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  th, td {
    padding: 15px 25px;
    border-top: none;
    border-bottom: 15px solid $grey;
    vertical-align: middle;
  }
}

.page-content--identity,
.page-content--address {
  .form-control,
  .form-control:focus,
  .form-control:active {
    background: $white;
    padding: 20px 28px;
    border: none;
    box-shadow: none;

    ::-webkit-input-placeholder { /* Edge */
      color: $main-color;
      font-weight: 500;
      text-transform: uppercase;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $main-color;
      font-weight: 500;
      text-transform: uppercase;
    }

    ::placeholder {
      color: $main-color;
      font-weight: 500;
      text-transform: uppercase;
    }

    &:focus {
        border: none !important;
        box-shadow: none !important;
    }
  }

  label {
      color: $main-color;
      font-weight: 800;
      text-transform: uppercase;
  }

  .form-group,
  .form-row {
    margin-bottom: 28px;
  }
}

.orders {

  .order {
    background: $white;
    padding: 15px;

    h3 {
      font-size: 18px;
    }

    h3,
    .date,
    .total,
    .status {
      font-weight: 800;
      text-transform: uppercase;
      color: $text-color;
    }
  }
}

.page-content--order-confirmation {

  .table__title-head {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 15px;
    color: $text-color;
  }

  .order-confirmation-table {
    background: $white;
    border: none;
    padding: 15px;

    .details,
    .qty {
      font-size: 14px;
      font-weight: 800;
      text-transform: uppercase;
      color: $text-color;
    }

    .table td {
      border: none;
    }
  }

  .order-details-confirmation,
  .definition-list-body {

    ul li,
    p,
    a {
      font-size: 14px;
      font-weight: 500;
      color: $text-color;
    }
  }

}


/*** Checkout ***/
.checkout-process {

  .custom-radio {
    min-height: 26px;

    .custom-control-input {
      height: 26px;
      width: 26px;
      cursor: pointer;
    }

    .custom-control-label::before {
      height: 26px;
      width: 26px;
      border: 1px solid $text-color;
      box-shadow: none;
      top: 0;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $white;
      border-color: $main-color;
    }

    .custom-control-label::after {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      top: 6px;
      left: -9px;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: none;
      background: $main-color;
    }
  }

  .checkout-step {
    border: none;
    margin-bottom: 30px;

    @include media-breakpoint-desktop {
      .content.card-body {
        padding: 30px 60px;
      }
    }
  }

  .form-fields {
    @extend .row;
  }

  .form-group {
    @extend .col-12;
    margin-bottom: 25px;

    @include media-breakpoint-desktop {
      @extend .col-lg-6;
    }
  }

  .form-control,
  .custom-select {
    height: 43px;
    background: $grey;
    border: none;
    box-shadow: none; 

    &:focus {
      border: none;
      box-shadow: none; 
    }
  }

  .custom-control {
    width: 100%;
  }

  label {
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: $text-color;
  }

  #login-form {
    label {
      font-weight: 500;
      color: $main-color;
    }
  }

  .form-group_forgot-password {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    
    label {
      margin-bottom: 0;
    }

    .custom-checkbox {
      width: auto;
      padding: 0 !important;
    }

    .forgot-password a {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: underline;
      color: $main-color;
    }
  }

  #content-checkout-personal-information-step {

    label:not(.custom-control-label) {
      width: 100%;
    }

    .form-group:first-child {
      padding-bottom: 25px;
      border-bottom: 1px solid $main-color;
      display: block;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      color: $main-color;

      .custom-control {
        width: auto;

        &:first-of-type {
          margin-right: 40px;
        }

        .custom-control-label {
          font-size: 14px;
          font-weight: 500;
          color: $text-color;
          text-transform: initial;
          padding-left: 26px;
          line-height: 26px;
          margin-bottom: 0;
        }
      }
    }

    .nav-tabs-info {
      background: $dark-grey;
      border-radius: 21px;
      margin-bottom: 45px;
      border: none;

      .nav-item {
        @extend .col;
        padding: 0 !important;
      }

      .nav-link {
        padding: 10px 0;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        color: $text-color;
        border: none;

        &.active {
          background: $main-color;
          border-radius: 21px;
          color: $white;
          font-weight: 800;
          border: none;
        }
      }

      @include media-breakpoint-mobile {
        background: none;
        margin:  0;
        display: block;

        .nav-item {
          display: block;
          width: 100%;
        }

        .nav-link {
          background: $dark-grey;
          display: block;
          width: 100%;
          border-radius: 21px;
          margin-bottom: 20px;
        }
      }

    }
  }

  .address-item {
    .custom-control-label:before,
    .custom-control-label:after {
      display: none;
    }

    .address-header {
      background: none;
      padding: 15px 0;
      text-align: left;
    }

    .address-body {
      background: $grey;
    }

    label {
      border-color: $white;
    }

    &.selected {
      border: 1px solid $main-color;
    }
  }

  .delivery-option {
    margin-bottom: 23px;

    label:not(.custom-control-label) {
      background: $grey;
      padding: 13px 20px;
      margin-bottom: 0;
      color: $main-color;

      .carrier-price {
        font-size: 14px;
        font-weight: 800;
        color: $main-color;
      }
    }

  }

  .payment-options {

    .custom-radio {
      margin-bottom: 23px;
    }

    .payment-name {
      background: $grey;
      padding: 13px 20px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 800;
      text-transform: uppercase;
      color: $main-color;
    }
  }

  #conditions-to-approve {
    background: $grey;
    border: none;

    .custom-control-label {
      margin-bottom: 0;
      padding-left: 15px;

      &:before,
      &:after {
        height: 16px;
        width: 16px;
        top: 3px;
      }
    }
  }

}


#identity {
  label[for*='f-id_gender'] {
    display: block;
  }
}