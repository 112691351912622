@import "abstracts/variables-bootstrap";
@import "bootstrap";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "abstracts/buttons";
@import "partials/fonts";

/** LAYOUT **/
@import "layout/header";
@import "layout/footer";
@import "layout/mainmenu";


/** PAGES **/
@import "pages/cart";
@import "pages/category";
@import "pages/home";
@import "pages/order";
@import "pages/product";
@import "pages/contact";
@import "pages/connexion";
@import "pages/brands";
@import "pages/checkout";

@import "partials/commons";
@import "partials/lazysizes";
/** COMPONENT **/
@import "components/sticky";
@import "components/alert";
@import "components/block-social";
@import "components/cart";
@import "components/categories";
@import "components/customer";
@import "components/footer";
@import "components/imageslider";
@import "components/offcanvas";
@import "components/products";
@import "components/searchbar";
@import "components/breadcrumb";
@import "components/push";
@import "components/teams";

@import "components/facet";
@import "components/search-widget";
@import "components/slick";
@import "components/slick-theme";
@import "components/productcomments";
@import "components/utilities";
@import "partials/bs_alpha";

