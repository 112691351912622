// .l-footer{
//   background-color: $footer-bg;
//   margin-top: $footer-margin-top;
//   padding-top: $footer-padding-top;
// }

#footer {
    .footer-container {
        .col-md-3 {
            @include media-breakpoint-up(md) {
                max-width: 286px;
            }
        }
    }
}