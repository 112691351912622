.section-pushs {
    --content-bg: rgb(4, 40, 97, .85);
    --content-bg-hover: rgb(6, 173, 242, .85);
    --content-color: #fff;
    --content-title-color: #06ADF2;
    --content-title-color-hover: #042761;
    --border-color: #042761;
    --border-color-hover:#06adf2;

    img {
        width: 100%;
        object-fit: cover;
        transition: all .3s ease-in-out;
    }
    .section-push--item-left,
    .section-push--item-right {
        position: relative;
        height: 100%;

        @include media-breakpoint-up(md) {
            min-height: 200px;
        }
    }
}

.section-push--item-left {
    border-left : 5px solid var(--border-color);
}

.section-push--item-right {
    border-right : 5px solid var(--border-color);
}



.section-push--item {
    border-bottom : 5px solid var(--border-color);
    overflow: hidden;

    .no-touch &:hover {
        img:not(.no-scale) {
            transform: scale(1.2);
        }

        .section-push--item-left,
        .section-push--item-right,
        & {
            border-color: var(--border-color-hover);
        }


        .section-push--content {
            background-color: var(--content-bg-hover);
            h2 {
                color: var(--content-title-color-hover);
            }

            &.section-push--content-bg-inverse {
                background-color: var(--content-bg);

                h2 {
                    color: var(--content-title-color);
                }
            }

            &.section-push--content-animate-height {
                height: 100%;
            }

            a {
                text-decoration: underline;
            }
        }

        
    } 
}

.section-push--content {
    background-color: var(--content-bg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    color: var(--content-color);
    font-family: 'Barlow';
    text-transform: uppercase;
    padding: 14px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all .3s ease-in-out;

    &.section-push--content-bg-inverse {
        background-color: var(--content-bg-hover);
        h2 {
            color: var(--content-title-color-hover);
        }
    }

    
}

.section-push--content__right,
.section-push--content__left {
    max-width: 313px;
}

.section-push--content__right {
    left: auto;
}

.section-push--content__left {
    right: auto;
}

.section-push--content h2,
.section-push--content p,
.section-push--content h3 {
    font-family: 'Barlow';
    font-weight: 900;
    line-height: 1.21;
    transition: color .3s ease-in-out;
}

.section-push--content h2 {
    color: var(--content-title-color);
}
.section-push--content p,
.section-push--content h3 {
    color: var(--content-color);
}

.section-push--content a {
    font-size: 18px;
    font-weight: 900;
    line-height: 1.22;
    color: var(--content-color);
    // position: absolute;
    // bottom: 37px;
}

@include media-breakpoint-desktop() {
    .section-push--content {
        &.section-push--content-animate-height {
            height: 54px;
            overflow: hidden;
        }
    }
}

@include media-breakpoint-mobile {
    .section-push--content {
        padding: 20px 17px;
    }
    .section-push--content h2,
    .section-push--content h3 {
        font-size: 16px;
        line-height: 1.19;
    }
    .section-push--content a {
        font-size: 14px;
        line-height: 1.21;
    }
    .section-push--content__right,
    .section-push--content__left {
        max-width: 190px;
    }
}