.btn {
    padding: 17px 34px;
    font-family: $barlow-font;
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    // z-index: 1;
    // letter-spacing: 1px;
    transition: all ease-in-out .4s;
    outline: none;

    @include media-breakpoint-down(md) {
        font-size: 20px;
        padding: 18px 15px;
    }


    &:hover,
    &:focus,
    &:active {
        box-shadow: none !important;
        outline: none;
    }

    &-primary {
        background: $main-color;

        &:hover,
        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active {
            background: $text-color;
            border: none !important;
            box-shadow: none !important;
        }
    }

    &-grey {
        background: $btn-grey;

        &:hover,
        &:focus,
        &:active {
            background: $btn-grey;
            color: $white;
        }
    }

    // &-outline {
    //     background: none;
    //     border: 1px solid $main-color;
    //     color: $main-color;

    //     &:hover,
    //     &:focus,
    //     &:active {
    //         background: $text-color;
    //         color: $white;
    //         border-color: $text-color;
    //     }
    // }

    &-link {
        font-size: 14px;
        padding: 0;
        color: $text-color;
        border: none;

        &:hover,
        &:focus,
        &:active {
            background: none;
            color: $text-color;
            border: none;
            text-decoration: underline;
        }
    }
}