html {
  font-size: 62.5%;
  overflow-x: hidden;
}
body{
  background-color: $wrapper-bg;
  font-family: $barlow-font;
  color: $text-color;
  overflow-x: hidden;
}
a{
  text-decoration: none;
}
sup{
  top:-0.3em;
}

b, strong {
  font-weight: 900;
}

.xtbold {
  font-weight: 800 !important;
}

.mdbold {
  font-weight: 500 !important;
}

.form-control {
  color: $main-color;
  font-weight: 500;
  text-transform: uppercase;
  height: 50px;
}

label {
  color: $text-color;

  small.text-muted {
    color: $text-color !important;
  }

  &.labelfocus {
    color: $main-color;

    small.text-muted {
      color: $main-color !important;
    }
  }
}

.section--title {
  position: relative;
  font-size: 26px;
  font-weight: 800;
  text-transform: uppercase;
  color: $text-color;
  text-align: left !important;
  padding-left: 33px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 5px solid $main-color;

  img {
    height: 44px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 10px;
    width: 10px;
    background: $main-color;
  }

  @include media-breakpoint-mobile {
    font-size: 20px;
    line-height: 20px;
    padding-left: 22px;
    padding-bottom: 17px;
    margin-bottom: 20px;

    &:before {
      height: 9px;
      width: 9px;
    }
  }
}

.show-more {
  font-family: $barlow-font;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: normal;
  color: $text-color;
  text-transform: uppercase;
  font-weight: 400;

  .no-touch &:hover {
    text-decoration: underline;
    font-weight: 900;
    color: $text-color;
  }
  
 }

.bloc--title {
  background: $main-color;
  padding: 16px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 900;
  color: $white;
  line-height: 1.2;
  text-transform: uppercase;
}

.border,
.border-top,
.border-right,
.border-left,
.border-bottom {
  border-color: $main-color !important;
}

.l-wrapper{
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }
  &--boxed{
    @extend .container;

  }

}
.page-wrapper,.card-block{
  @extend .card;

}
.page-header{
  @extend .card-header;
  h1{
    margin-bottom: 0;
  }
}
.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  &:empty{
    display: none;
  }

}

.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
// .l-footer{
//   background-color: $footer-bg;
//   margin-top: $footer-margin-top;
//   padding-top: $footer-padding-top;
// }

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;
  width: 100%;

}
.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

$touchspin-background: $main-color;
$touchspin-color-btn: $black;
$touchspin-border-color: $black;

.bootstrap-touchspin {
  box-shadow: none;
  border: none;

  >input {
    background: $touchspin-background;
    color: $white;
    font-size: 18px;
    font-weight: 500;
    max-width: 65px;
    border: none;
    box-shadow: none;
    margin: 0 6px;
    height: auto;
    padding: 4px;
  }

  .btn {
    position: relative;
    z-index: 1;
    color: $touchspin-color-btn;
    background-color: $white;
    padding: 2px 11px;
    border: 1px solid $touchspin-border-color;
    width: 33px;

  }

  .input-group-btn {
    display: flex;

    &:first-child {
      margin-right: -$input-border-width;
    }

    &:last-child {
      margin-left: -$input-border-width;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background-color: rgba(0,0,0,0.05);
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }

  &--visbility-style {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    padding-top: 0 !important;
    height: auto;

    img {
      visibility: hidden;
    }
  }
}

.rc:not(.rc--visbility-style)>* {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.card-header{
  background: $dark-grey;
  padding: 15px;
  border: none;

  .step-title{
    text-align: center;
    margin-bottom: 0;
    color: $white;
    font-size: 24px;
    font-weight: 800;

    i {
      vertical-align: middle;
    }

    .step-edit {
      display: flex;
      align-items: baseline;
      margin-top: 4px;
      font-size: 12px;
      font-weight: 800;
      color: $text-color !important;
    }
  }
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $main-color;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  // padding: $spacer;
  margin-bottom: $spacer;
  // background-color: $gray-200;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-weight: $font-weight-bold;
}
  .carrier-price{
    font-weight: bold;
    font-size: $font-size-lg;
    color: $primary;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    width: 100%;
    // max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}

.no-touch .scale-hover {
  transition: all .3s ease-out;
  &:hover {
    transform: scale(1.2);
    transition: all .3s ease-in;
  }
}

.image-with-caption {
  position: relative;
  display: block;
  color: #fff;
  overflow: hidden;

  .no-touch &:hover {
    img {
      transform: scale(1.1);
      transition: all .3s ease-out;
    }
    .caption  {
      background-color: rgba($main-color, 0.85);

      span {
        opacity: 1;
      }
    }
  }

  img {
    display: block;
    transition: all .3s ease-in;
  }
  .caption {
    padding: 2px 4px 3px 22px;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    min-height: 82px;
    transition: all .3s ease;
    background-color: transparent;
    flex-direction: column;
    justify-content: center;

    &.highlighting {
      background-color: rgba($main-color, 0.85);
      span {
        opacity: 1;
      }
      img {
        transform: scale(1.2);
        transition: all .3s ease-out;
      }
    }

    span {
      opacity: 0;
      transition: opacity .3s ease;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2;
      color: #002760;
      margin-top: 0;
      display: block;
      text-transform: uppercase;
    }
  }

  .caption-title {
    font-size: 30px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 0;
    color: $white;
    text-transform: uppercase;
  }
  
}

@include media-breakpoint-mobile {
  .row-full-mobile {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  }

  .show-more {
    text-decoration: underline;
    font-weight: 900;
  }

  .image-with-caption {
      img {
        object-fit: cover;
        width: 100%;
      }
     .caption {
       padding: 5px 10px 5px 13px;
       background-color: rgba($main-color, 0.85);
       min-height: 33px;
       span {
         display: none;
       }
     }

     .caption-title {
       font-size: 18px;
       margin-top: 0;
     }
  }
}