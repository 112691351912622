.page-wrapper--contact {
	border: none;
	background: none;

	::-webkit-input-placeholder { /* Edge */
	  color: $main-color;
	  font-weight: 500;
	  text-transform: uppercase;
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  color: $main-color;
	  font-weight: 500;
	  text-transform: uppercase;
	}

	::placeholder {
	  color: $main-color;
	  font-weight: 500;
	  text-transform: uppercase;
	}

	.page-content--contact {
		padding: 0;
	}

	h1 {
		@extend .section--title;
	}

	p {
		font-weight: 900;
		text-transform: uppercase;
		color: $text-color;
		margin-bottom: 25px;
	}

	.custom-select {
		height: 52px;
		background: $grey;

		option {
			background: $grey;
			color: $main-color;
			text-transform: uppercase;

			&[value="3"] {
				color: $main-color;
			}
		}
	}

	.form-control {
	  padding: 26px !important;
	}

	input:not(.btn),
	select,
	textarea {
	  background-color: $grey !important;
	  border: none !important;
	  padding-left: 30px !important;
	  box-shadow: none !important;

	  &:focus {
	    border: none !important;
	    box-shadow: none !important;
	  }
	}

	label {
		color: $main-color;
		font-weight: 800;
		text-transform: uppercase;
	}

	.btn {
		@extend .btn-block;
	}

	.form-group {
		margin-bottom: 15px;
	}
}

#contact {
	.koolgang_footer {

		.bloc .footer__title br {
			display: none;
		}
	}

	.contact-page-widget .logo {
		margin-bottom: 115px;
		margin-top: 85px;
	}
}