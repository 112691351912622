.block-category {
  background: $main-color;
  border-bottom: none;
  border-left: none;
  border-right: none;
  padding: 12px 20px;

  h1 {
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    color: $white;
  }

  p {
    color: $white;
    margin-bottom: 0;
  }

  @include media-breakpoint-mobile {
    border: none;
  }
}

.products {
  @extend .row;
  align-items:stretch;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    height: 100%;
    top: 0;
    left: 0;
    width: 4px;
    background-color: #fff;
    position: absolute;
    z-index: 1;
  }

  .slick-slide {
    > div {
      overflow: hidden;
    }
  }

  .product-miniature {
    overflow: hidden;
    position: relative;
    background-color: $white;

    &:before {
      content: "";
      height: 100%;
      width: 1px;
      background: $main-color;
      position: absolute;
      left: 0;
      top: 0;
    }

    .card-product {
      border-width: 0;
    }

    .card-body {
      transition: all .3s ease-in-out;
      @include media-breakpoint-desktop {
        margin: 0 -15px;
      }
    }

    &:hover {
      .card-body {
        background-color: $main-color;
        position: relative;
        &:before { // HACK
          content: "";
          background-color: $main-color;
          position: absolute;
          left: 0;
          width: 100%;
          bottom: -22px;
          height: 100vh;
          z-index: -1;
        }
      }
      .product-title a,
      .product-brand-name,
      .product-price-and-shipping .price {
        color: $white;
      }
    }
  }

  @include media-breakpoint-desktop {
    padding: 0 43px 0 61px;
    &:before {
      left: 61px;
    }
    .product-title {
      max-height: 22px;
    }
  }

}
.product-miniature{
  @extend .col-6;

  .no-gutters & {
    padding-right: 0;
    padding-left: 0;
  }

}
@include media-breakpoint-desktop {
  .product-miniature{
    @include make-col(4);
  }

  .layout-full-width .product-miniature{
    @include make-col(3);
  }
}

@media (max-width: 320px) {
  .product-miniature{
    @include make-col(12);
  }
}

.slick-slide .product-miniature{
  max-width: 100%;
}
/* Product miniature*/
.card-product.card{
  height:100%;
  position: relative;
  background-color: $pm-bg-color;
  border-width: 0;
  border-left-width: $pm-border-width;
  border-color: $pm-border-color;
  &:hover
  {
    .highlighted-informations{
      transform:translateY(0);
    }

  }

  .discount{
    display:none;
  }
}

.card-img-top {
  overflow: hidden;
  .product-thumbnail img{
    width: 100%;
  }
}

.highlighted-informations{
  position: absolute;
  bottom: 0;
  width:100%;
  background-color: $pm-highlight-bg-color;
  transform:translateY(100%);
  transition:transform .3s;
  .card-product:hover & {
    transform:translateY($pm-highlight-translateY);
  }
}
.quick-view{
  cursor:pointer;
}

.product-flags{
  position:absolute;
  top:0;
  margin-bottom: 0;
  z-index: 1;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-flag{
  margin-bottom: $spacer/2;
  padding: 2px 11px;
  color: #fff;
  background: $main-color;
  text-transform: uppercase;
  font-weight: 800;
  // box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);

  &.discount-product{
    background: #F20618;
  }
}
.color{
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);
  border: 1px solid rgba(0,0,0,.05);
  cursor: pointer;
  background-size: contain;
}

.product-description {
  color: $pm-name-color;
}

.product-description,
.product-composition {
  text-transform: uppercase;
  font-weight: $pm-name-font-weight;
}

.product-brand-name {
  display: block;
  font-size: $pm-brand-name-font-size;
  line-height: $pm-name-line-height;
  font-weight: $pm-brand-name-font-weight;
}

.product-title{
  color: $pm-name-color;
  font-size: $pm-name-font-size;
  text-decoration: none;
  font-weight: $pm-name-font-weight;
  margin-bottom: $pm-name-margin-bottom;
  line-height: $pm-name-line-height;
  overflow: hidden;
  & a {
    color: $pm-name-color;
    &:hover{
      color: $pm-name-hover-color;
      border-bottom: $pm-name-hover-border-width solid $pm-name-hover-border-color;
    }

  }
  .card-product:hover & {
    color: $pm-name-hover-color;
    & a {
      color: $pm-name-hover-color;
    }
  }
}

/* PRODUCT PAGE */

.products-imagescover{
  box-shadow:$card-box-shadow;;
}
.product-img:not(:first-child) .rc {
  display: none;
}
.slick-initialized .product-img .rc{
  display: block;
}

.product-thumbs{
  width: 100%;
  height: $product-thumb-wrap-height;
  margin: 20px auto 0;
  &[data-count="2"]{
    width:$product-thumb-wrap-width-2;
  }
  .slick-slide {
    padding: 0 13px;
    > div{
      cursor: pointer;
    }
  }
  &:not(.slick-initialized) > div:not(:first-child){
    display: none;
  }
  
  .slick-list {
    margin-left: -13px;
    margin-right: -13px;
  }

}
.product-thumb{
  // margin: $product-thumb-margin;
  //padding:5px;
  //height:  auto;
  // width:$product-thumb-width;
  // height:$product-thumb-height;
  border:2px solid transparent;
  &.slick-current {
    border: 2px solid $grey;
  }
}
.btn-zoom{

  position: absolute;
  right: $spacer;
  bottom: $spacer;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1;
  & .material-icons{
  font-size: 3*$font-size-base;
  }
}
// .images-container:hover .btn-zoom{
//   display: block;
// }
.label-color{
  line-height: 1;
  margin-right: $spacer/2;
}
.product-variants-item .color{
  width:2rem;
  height:2rem;
  border: 2px solid transparent;
}

.input-color:checked+span, .input-color:hover+span, .input-radio:checked+span, .input-radio:hover+span {
  border-color: $colorbox-border-color;
}

//product prices
.product__product-price.has-discount{
  .current-price{
    display: inline-block;
  }
}
// .price{
//   color:$product-price-current-color;
//   font-size:$product-price-current-font-size;
//   font-weight:$product-price-current-font-weight;
//   font-family: $product-price-current-font-family;
//   &.current-price-discount{
//     color:$product-price-discount-color;
//     font-size:$product-price-discount-font-size;
//     font-weight:$product-price-discount-font-weight;
//     font-family: $product-price-discount-font-family;
//   }
// }

.product-price-and-shipping {

  .price {
    font-size: 24px;
    line-height: 29px;
    font-weight: 800;
    color: $text-color;

    &.current-price-discount {
      color: #F20618;
    }
  }
}

.regular-price{
  color: $btn-grey;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-decoration: line-through;
  display: block;
}
.discount{
  text-transform: uppercase;
  color: #FFF;
  background-color: $product-price-discount-color;
  font-weight: 600;
  display: inline-block;
  padding: .25*$spacer;
}

//product add to cart
.btn-add-to-cart{
  //@extend .d-flex;
  text-transform: uppercase;
  font-weight: 800;
  white-space: nowrap;
}

//product tabs
.product-tabs{
  .nav-tabs{
    font-size: $font-size-base * .9;
  }
  //@extend .card;
  //@extend .card-block
  //box-shadow:$card-box-shadow;;
}
.card--reassurance{
  box-shadow:$card-box-shadow;;
}

// modal add to cart
.cart-content{
  padding: $spacer;
  background: theme-color('light');
}

//product pack
.pack-product-container{
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
}
.mask{
  width: 55px;
}
.thumb-mask{
  padding: $spacer 0;
}
.pack-product__img{
  border: 1px solid $border-color;
}
.pack-product-name{
  @extend .flex-grow-1;
  @extend .mx-4;
}
.pack-product-quantity{
  border-left:1px solid $border-color;
}
.pack-product-price{
  padding-right: $spacer/2;

}
.pack-product-quantity{
  @extend .d-flex;
  @extend .align-items-center;
  @extend .align-self-stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image{
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name{
  color: $primary;
  font-weight: $font-weight-bold;
}
.product-total{
  font-weight: $font-weight-bold;
}
.cart-content{
  > p:not(:last-child){
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image{
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right{
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, .15);
    }
    .modal-body{
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size*$btn-line-height) + $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image{
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    @extend .spinner-border;
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading{
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection{
  justify-content: space-between;
  align-items: center; 
}
.pack-miniature-item{
  margin-bottom: $spacer / 2;
}
.ratio1_1{
  display: block;
}
.pack-product-name{
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product-miniature{
  margin-top: 52px;
}
.product__card-img{
  position: relative;
}
.product__card-desc{
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;
  text-align: center;
}
.highlighted-informations{
  text-align: center;
  padding: $spacer / 2;
}
.quantity__label{
  margin-top: 0;

}
.product-add-to-cart{
  .product-quantity{
  margin-top: -($spacer);
  }
  .qty{
    margin: 0 $spacer;
    margin-top: $spacer;

  }
  .add{
    margin-top: $spacer;
  }

  [class*="col"]:empty {
    display: none;
  }
}
.product-customization{
  margin-bottom: $spacer * 1.5;
}
.product-discounts{
  margin-bottom: $spacer * 1.5;
}
.product__quantity{
  margin-bottom: 0;
}
.sort-by-row{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.total__item-show{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links{
  display: flex;
  justify-content: center;
}
.product__download{
  text-transform: uppercase;
}
.card--address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address{
  margin-top: $spacer;
}
.invoice-address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.nav-tabs{
  justify-content: center;
}

@include media-breakpoint-mobile {
  .product-flags {
    .product-flag {
      font-size: 9px;
      line-height: 1.22;
      padding: 2px 5px;
    }
  }

  .product-miniature {
    margin-top: 35px;
  }
  
  .product-brand-name,
  .product-title,
  .regular-price {
    font-size: 14px;
  }

  .product-price-and-shipping .price {
    font-size: 18px;
    line-height: 22px;
  }

}

.pagination {

  .page-item {

    .page-link {
      background-color: transparent;
      border: none;
      color: $text-color;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 10px;
    }

    &.active {
      .page-link {
        font-weight: 800;
      }
    }
  }
}